import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle, navItems }) => {
  return (
    <footer className="flex bg-scporange pl-4 pr-4 min-h-120">
        <div className="flex flex-wrap-reverse w-full justify-between text-scpdarkgrey mt-auto mb-auto">
          <p className="text-center flex-grow mb-auto mt-auto font-bold lg:flex-grow-0">{new Date().getFullYear()} ©{siteTitle}</p>
          {navItems !== null && navItems.length > 0 &&
            <div className="w-full mb-auto mt-auto lg:w-auto">
              <div className="flex flex-wrap justify-center">
              {navItems.map((footerItem, i) => (
                <p key={footerItem.wordpress_id}>
                  {i > 0 && <span className="pl-2 pr-2">•</span>}
                  <Link className="hover:text-scplightgrey" to={footerItem.url}>
                      {footerItem.label}
                  </Link>
                </p>
              ))}
              </div>
            </div>
          }
        </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.object),
}

Footer.defaultProps = {
  navItems: [],
}

export default Footer
