import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import HeaderImage from "../components/header-image"
import BurgerButton from "../components/burger-button"


const Header = ({ navItems }) => {
  return (
    <header className="flex bg-scporange mb-4">
      <div className="w-1200 m-auto flex flex-wrap space-between px-4 xl:px-0">
        <Link aria-label="Home" className="flex py-1" to="/">
          <HeaderImage/>
        </Link>
        <BurgerButton>
          {navItems.map((navItem, i) => (
            <Link className="mt-4 text-scpdarkgrey text-4xl font-nav block hover:text-scplightgrey lg:inline-block lg:pl-8 lg:mt-0" 
              to={navItem.url} key={navItem.wordpress_id}>
              {navItem.label}
            </Link>
          ))}
        </BurgerButton>
      </div>
    </header>
  )
}

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
}

export default Header
