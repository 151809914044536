/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 * 
 * Declare CSS modules first so they can be overriden by child components
 */
import "./tailwind.css"

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery($headerRegex : String = "/(Main)/", $footerRegex : String = "/(Footer)/") {
      site {
        siteMetadata {
          title
        }
      }
      HeaderItems : wpMenu(name: {regex: $headerRegex}) {
        menuItems {
          nodes {
            label
            url
            databaseId
          }
        }
      }
      FooterItems : wpMenu(name: {regex: $footerRegex}) {
        menuItems {
          nodes {
            label
            url
            databaseId
          }
        }
      }
    }
  `)

  return (
    <>
      <Header navItems={data.HeaderItems.menuItems.nodes} />
      <div className=" m-auto max-w-full pl-4 pr-4 xl:max-w-1140 xl:p-0">
        <main>{children}</main>
      </div>
      <Footer siteTitle={data.site.siteMetadata.title} navItems={data.FooterItems.menuItems.nodes} />
      <CookieConsent
        disableStyles={true}
        location="top"
        containerClasses="w-full min-h-16 fixed z-50 py-4 px-8 flex justify-between flex-wrap bg-scplightgrey text-scpdarkgrey"
        contentClasses="my-auto flex-grow text-scpdarkgrey text-center font-bold md:text-left"   
        buttonClasses="m-auto bg-scporange text-scpdarkgrey rounded-lg p-4 font-bold hover:bg-scpdarkgrey hover:text-scplightgrey md:mx-0"    
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
