import React from "react"
import PropTypes from "prop-types"

import BurgerImage from "./burger-icon"


const BurgerButton = ({children}) => {
  function burgerToggle() {
    let menu = document.getElementById('menu');
    menu.classList.toggle("hidden");
  }
  return (
    <>
    <div className="my-auto block ml-auto lg:hidden">
      <button id="burger-button" className="border rounded border-scpdarkgrey hover:border-scplightgrey px-3 py-2" aria-label="Menu Button" onClick={burgerToggle}>
        <BurgerImage/>
      </button>
    </div>
    <div id="menu" className="mb-4 w-full block flex-grow justify-end lg:my-auto lg:flex lg:w-auto hidden">
      {children}
    </div>
    </>
  )
}

BurgerButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BurgerButton