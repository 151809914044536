import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HeaderImage = () => {
  const data = useStaticQuery(graphql`
    query {
      HeaderImage : wpMediaItem(title: {eq: "Logo-Outline-NoEye"}) {
        localFile {
          childImageSharp {
            fixed(width: 90, height: 90) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  `)

  return (
    data.HeaderImage.localFile !== null &&
      <Img 
        className=""
        resolutions={data.HeaderImage.localFile.childImageSharp.fixed} 
        key={data.HeaderImage.localFile.childImageSharp.fixed.src}
      />
  )
}

export default HeaderImage
