import React from "react"

const BurgerImage = () => {
  return (
    <>
    <div className="burger-bar"></div>
    <div className="burger-bar"></div>
    <div className="burger-bar"></div>
    </>
  )
}

export default BurgerImage
